import revive_payload_client_4sVQNw7RlN from "/tmp/build_9a90b56e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/tmp/build_9a90b56e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/tmp/build_9a90b56e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/tmp/build_9a90b56e/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import supabase_client_I4Hv4qpJHd from "/tmp/build_9a90b56e/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_yVLowv6hDl from "/tmp/build_9a90b56e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/tmp/build_9a90b56e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/tmp/build_9a90b56e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/tmp/build_9a90b56e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/tmp/build_9a90b56e/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/tmp/build_9a90b56e/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/tmp/build_9a90b56e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import dompurify_qvTkTTURiH from "/tmp/build_9a90b56e/plugins/dompurify.ts";
import errorHandlerVue_jzZdtbxRQN from "/tmp/build_9a90b56e/plugins/errorHandlerVue.ts";
import gtm_client_OzmBuHLRIb from "/tmp/build_9a90b56e/plugins/gtm.client.ts";
import hardRefresh_client_PRJCZchwX1 from "/tmp/build_9a90b56e/plugins/hardRefresh.client.ts";
import recaptcha_client_ElRG0N5AcO from "/tmp/build_9a90b56e/plugins/recaptcha.client.ts";
import scrollPosition_client_rDuZWgOCV2 from "/tmp/build_9a90b56e/plugins/scrollPosition.client.ts";
import sentry_client_shVUlIjFLk from "/tmp/build_9a90b56e/plugins/sentry.client.ts";
import webVitals_client_rMA3vEnjdM from "/tmp/build_9a90b56e/plugins/webVitals.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  supabase_client_I4Hv4qpJHd,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  dompurify_qvTkTTURiH,
  errorHandlerVue_jzZdtbxRQN,
  gtm_client_OzmBuHLRIb,
  hardRefresh_client_PRJCZchwX1,
  recaptcha_client_ElRG0N5AcO,
  scrollPosition_client_rDuZWgOCV2,
  sentry_client_shVUlIjFLk,
  webVitals_client_rMA3vEnjdM
]