import { default as _36100_45ouncesDWMib735ZQMeta } from "/tmp/build_9a90b56e/pages/$100-ounces.vue?macro=true";
import { default as accessibility9GNSgS4nTbMeta } from "/tmp/build_9a90b56e/pages/accessibility.vue?macro=true";
import { default as _91slug_93dIFJd0g4PAMeta } from "/tmp/build_9a90b56e/pages/brands/[slug].vue?macro=true";
import { default as build_45a_45packGhPYk8poNIMeta } from "/tmp/build_9a90b56e/pages/build-a-pack.vue?macro=true";
import { default as cannabis_45pricingNGhPbVunprMeta } from "/tmp/build_9a90b56e/pages/cannabis-pricing.vue?macro=true";
import { default as checkoutVNLCZrelLUMeta } from "/tmp/build_9a90b56e/pages/checkout.vue?macro=true";
import { default as contactMuDh8DhCkGMeta } from "/tmp/build_9a90b56e/pages/contact.vue?macro=true";
import { default as confirmyjdJH6SJukMeta } from "/tmp/build_9a90b56e/pages/dashboard/confirm.vue?macro=true";
import { default as indexHp4qu8FNDCMeta } from "/tmp/build_9a90b56e/pages/dashboard/index.vue?macro=true";
import { default as _91id_93q5LWbluDIFMeta } from "/tmp/build_9a90b56e/pages/dashboard/jobs/[id].vue?macro=true";
import { default as createXEweLfaNaBMeta } from "/tmp/build_9a90b56e/pages/dashboard/jobs/create.vue?macro=true";
import { default as indexdL6U78ADU5Meta } from "/tmp/build_9a90b56e/pages/dashboard/jobs/index.vue?macro=true";
import { default as loginnu0K2JifTGMeta } from "/tmp/build_9a90b56e/pages/dashboard/login.vue?macro=true";
import { default as bundle_45image_45builderq7jKBW5kvZMeta } from "/tmp/build_9a90b56e/pages/dashboard/media/bundle-image-builder.vue?macro=true";
import { default as indexBGEPFSrx16Meta } from "/tmp/build_9a90b56e/pages/dashboard/media/index.vue?macro=true";
import { default as indexuuagDDaoESMeta } from "/tmp/build_9a90b56e/pages/dashboard/menu/index.vue?macro=true";
import { default as _91_46_46_46slug_93boDG6rqQrSMeta } from "/tmp/build_9a90b56e/pages/dashboard/pages/[...slug].vue?macro=true";
import { default as homebTuCWj0CdVMeta } from "/tmp/build_9a90b56e/pages/dashboard/pages/home.vue?macro=true";
import { default as index54OyLSGPFUMeta } from "/tmp/build_9a90b56e/pages/dashboard/pages/index.vue?macro=true";
import { default as _91id_93BBxSlqBMRIMeta } from "/tmp/build_9a90b56e/pages/dashboard/products/[id].vue?macro=true";
import { default as index0QZjnf0HU3Meta } from "/tmp/build_9a90b56e/pages/dashboard/products/index.vue?macro=true";
import { default as forecastsxzos1cULRVMeta } from "/tmp/build_9a90b56e/pages/dashboard/reporting/forecasts.vue?macro=true";
import { default as _91id_93iozQZJRoi1Meta } from "/tmp/build_9a90b56e/pages/dashboard/reviews/[id].vue?macro=true";
import { default as indexLo82uxylNpMeta } from "/tmp/build_9a90b56e/pages/dashboard/reviews/index.vue?macro=true";
import { default as settingspjaXn9gkzFMeta } from "/tmp/build_9a90b56e/pages/dashboard/settings.vue?macro=true";
import { default as themelGCerdTQWyMeta } from "/tmp/build_9a90b56e/pages/dashboard/theme.vue?macro=true";
import { default as _91id_93wvPfQpCHanMeta } from "/tmp/build_9a90b56e/pages/dashboard/users/[id].vue?macro=true";
import { default as indexQKM0J1UTXLMeta } from "/tmp/build_9a90b56e/pages/dashboard/users/index.vue?macro=true";
import { default as indexyYOrwDP35PMeta } from "/tmp/build_9a90b56e/pages/dashboard/web-vitals/index.vue?macro=true";
import { default as dashboardTOD6H3l5zEMeta } from "/tmp/build_9a90b56e/pages/dashboard.vue?macro=true";
import { default as dispensary_45birthday_45deals_45michiganUE8T7N0hPeMeta } from "/tmp/build_9a90b56e/pages/dispensary-birthday-deals-michigan.vue?macro=true";
import { default as dispensary_45deals6PZguQABD7Meta } from "/tmp/build_9a90b56e/pages/dispensary-deals.vue?macro=true";
import { default as first_45time_45dispensary_45dealsEaOeaaOoaGMeta } from "/tmp/build_9a90b56e/pages/first-time-dispensary-deals.vue?macro=true";
import { default as glass_45jarsPtRCT1Pt5XMeta } from "/tmp/build_9a90b56e/pages/glass-jars.vue?macro=true";
import { default as grow_45facility_45tourm2l19KCQbpMeta } from "/tmp/build_9a90b56e/pages/grow-facility-tour.vue?macro=true";
import { default as indexQZxxw50kCWMeta } from "/tmp/build_9a90b56e/pages/index.vue?macro=true";
import { default as _91slug_93HTogBx1EWNMeta } from "/tmp/build_9a90b56e/pages/jobs/[slug].vue?macro=true";
import { default as indexG0pvMkDiBGMeta } from "/tmp/build_9a90b56e/pages/jobs/index.vue?macro=true";
import { default as cannabinoidsrobScHKL1oMeta } from "/tmp/build_9a90b56e/pages/learn/cannabinoids.vue?macro=true";
import { default as cannabis_45anatomyA6fTmF3XQGMeta } from "/tmp/build_9a90b56e/pages/learn/cannabis-anatomy.vue?macro=true";
import { default as cannabis_45historynC46LbJanWMeta } from "/tmp/build_9a90b56e/pages/learn/cannabis-history.vue?macro=true";
import { default as michigan_45dispensary_45purchase_45limitsbKy7CTmJHLMeta } from "/tmp/build_9a90b56e/pages/learn/michigan-dispensary-purchase-limits.vue?macro=true";
import { default as terpenes_45over_45potencyzY2O4jAnFNMeta } from "/tmp/build_9a90b56e/pages/learn/terpenes-over-potency.vue?macro=true";
import { default as what_45is_45cannabisk2sTLfjr8NMeta } from "/tmp/build_9a90b56e/pages/learn/what-is-cannabis.vue?macro=true";
import { default as michigan_45dispensary_45near_45indianaKkHP7mXK5TMeta } from "/tmp/build_9a90b56e/pages/michigan-dispensary-near-indiana.vue?macro=true";
import { default as michigan_45dispensary_45near_45ohio2LhIFoTqbKMeta } from "/tmp/build_9a90b56e/pages/michigan-dispensary-near-ohio.vue?macro=true";
import { default as newsletterCc1F5Si1WJMeta } from "/tmp/build_9a90b56e/pages/newsletter.vue?macro=true";
import { default as order_45trackerc9It78QyLsMeta } from "/tmp/build_9a90b56e/pages/order-tracker.vue?macro=true";
import { default as privacy_45policyqFx2DoVAW6Meta } from "/tmp/build_9a90b56e/pages/privacy-policy.vue?macro=true";
import { default as _91slug_93vfq4z6KypXMeta } from "/tmp/build_9a90b56e/pages/products/[slug].vue?macro=true";
import { default as quick_45order1LVlM89vw0Meta } from "/tmp/build_9a90b56e/pages/quick-order.vue?macro=true";
import { default as return_45policyyq2XCiJAJsMeta } from "/tmp/build_9a90b56e/pages/return-policy.vue?macro=true";
import { default as reviewsfeZhOx2B1PMeta } from "/tmp/build_9a90b56e/pages/reviews.vue?macro=true";
import { default as staging_45login8xbpU6WjQOMeta } from "/tmp/build_9a90b56e/pages/staging-login.vue?macro=true";
import { default as strain_45library8EE2b6oY6jMeta } from "/tmp/build_9a90b56e/pages/strain-library.vue?macro=true";
import { default as terms_45of_45useI5Yilq1mUkMeta } from "/tmp/build_9a90b56e/pages/terms-of-use.vue?macro=true";
import { default as thank_45youNsqbAkAbT7Meta } from "/tmp/build_9a90b56e/pages/thank-you.vue?macro=true";
import { default as weedys_45wearfZn6NZPr9iMeta } from "/tmp/build_9a90b56e/pages/weedys-wear.vue?macro=true";
import { default as wholesaleCHfsMmwPeCMeta } from "/tmp/build_9a90b56e/pages/wholesale.vue?macro=true";
export default [
  {
    name: "$100-ounces",
    path: "/$100-ounces",
    component: () => import("/tmp/build_9a90b56e/pages/$100-ounces.vue")
  },
  {
    name: "accessibility",
    path: "/accessibility",
    component: () => import("/tmp/build_9a90b56e/pages/accessibility.vue")
  },
  {
    name: "brands-slug",
    path: "/brands/:slug()",
    component: () => import("/tmp/build_9a90b56e/pages/brands/[slug].vue")
  },
  {
    name: "build-a-pack",
    path: "/build-a-pack",
    component: () => import("/tmp/build_9a90b56e/pages/build-a-pack.vue")
  },
  {
    name: "cannabis-pricing",
    path: "/cannabis-pricing",
    component: () => import("/tmp/build_9a90b56e/pages/cannabis-pricing.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/tmp/build_9a90b56e/pages/checkout.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/tmp/build_9a90b56e/pages/contact.vue")
  },
  {
    name: dashboardTOD6H3l5zEMeta?.name,
    path: "/dashboard",
    meta: dashboardTOD6H3l5zEMeta || {},
    component: () => import("/tmp/build_9a90b56e/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-confirm",
    path: "confirm",
    meta: confirmyjdJH6SJukMeta || {},
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/confirm.vue")
  },
  {
    name: "dashboard",
    path: "",
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-jobs-id",
    path: "jobs/:id()",
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/jobs/[id].vue")
  },
  {
    name: "dashboard-jobs-create",
    path: "jobs/create",
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/jobs/create.vue")
  },
  {
    name: "dashboard-jobs",
    path: "jobs",
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/jobs/index.vue")
  },
  {
    name: "dashboard-login",
    path: "login",
    meta: loginnu0K2JifTGMeta || {},
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/login.vue")
  },
  {
    name: "dashboard-media-bundle-image-builder",
    path: "media/bundle-image-builder",
    meta: bundle_45image_45builderq7jKBW5kvZMeta || {},
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/media/bundle-image-builder.vue")
  },
  {
    name: "dashboard-media",
    path: "media",
    meta: indexBGEPFSrx16Meta || {},
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/media/index.vue")
  },
  {
    name: "dashboard-menu",
    path: "menu",
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/menu/index.vue")
  },
  {
    name: "dashboard-pages-slug",
    path: "pages/:slug(.*)*",
    meta: _91_46_46_46slug_93boDG6rqQrSMeta || {},
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/pages/[...slug].vue")
  },
  {
    name: "dashboard-pages-home",
    path: "pages/home",
    meta: homebTuCWj0CdVMeta || {},
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/pages/home.vue")
  },
  {
    name: "dashboard-pages",
    path: "pages",
    meta: index54OyLSGPFUMeta || {},
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/pages/index.vue")
  },
  {
    name: "dashboard-products-id",
    path: "products/:id()",
    meta: _91id_93BBxSlqBMRIMeta || {},
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/products/[id].vue")
  },
  {
    name: "dashboard-products",
    path: "products",
    meta: index0QZjnf0HU3Meta || {},
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/products/index.vue")
  },
  {
    name: "dashboard-reporting-forecasts",
    path: "reporting/forecasts",
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/reporting/forecasts.vue")
  },
  {
    name: "dashboard-reviews-id",
    path: "reviews/:id()",
    meta: _91id_93iozQZJRoi1Meta || {},
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/reviews/[id].vue")
  },
  {
    name: "dashboard-reviews",
    path: "reviews",
    meta: indexLo82uxylNpMeta || {},
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/reviews/index.vue")
  },
  {
    name: "dashboard-settings",
    path: "settings",
    meta: settingspjaXn9gkzFMeta || {},
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/settings.vue")
  },
  {
    name: "dashboard-theme",
    path: "theme",
    meta: themelGCerdTQWyMeta || {},
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/theme.vue")
  },
  {
    name: "dashboard-users-id",
    path: "users/:id()",
    meta: _91id_93wvPfQpCHanMeta || {},
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/users/[id].vue")
  },
  {
    name: "dashboard-users",
    path: "users",
    meta: indexQKM0J1UTXLMeta || {},
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/users/index.vue")
  },
  {
    name: "dashboard-web-vitals",
    path: "web-vitals",
    component: () => import("/tmp/build_9a90b56e/pages/dashboard/web-vitals/index.vue")
  }
]
  },
  {
    name: "dispensary-birthday-deals-michigan",
    path: "/dispensary-birthday-deals-michigan",
    component: () => import("/tmp/build_9a90b56e/pages/dispensary-birthday-deals-michigan.vue")
  },
  {
    name: "dispensary-deals",
    path: "/dispensary-deals",
    component: () => import("/tmp/build_9a90b56e/pages/dispensary-deals.vue")
  },
  {
    name: "first-time-dispensary-deals",
    path: "/first-time-dispensary-deals",
    component: () => import("/tmp/build_9a90b56e/pages/first-time-dispensary-deals.vue")
  },
  {
    name: "glass-jars",
    path: "/glass-jars",
    component: () => import("/tmp/build_9a90b56e/pages/glass-jars.vue")
  },
  {
    name: "grow-facility-tour",
    path: "/grow-facility-tour",
    component: () => import("/tmp/build_9a90b56e/pages/grow-facility-tour.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_9a90b56e/pages/index.vue")
  },
  {
    name: "jobs-slug",
    path: "/jobs/:slug()",
    component: () => import("/tmp/build_9a90b56e/pages/jobs/[slug].vue")
  },
  {
    name: "jobs",
    path: "/jobs",
    component: () => import("/tmp/build_9a90b56e/pages/jobs/index.vue")
  },
  {
    name: "learn-cannabinoids",
    path: "/learn/cannabinoids",
    component: () => import("/tmp/build_9a90b56e/pages/learn/cannabinoids.vue")
  },
  {
    name: "learn-cannabis-anatomy",
    path: "/learn/cannabis-anatomy",
    component: () => import("/tmp/build_9a90b56e/pages/learn/cannabis-anatomy.vue")
  },
  {
    name: "learn-cannabis-history",
    path: "/learn/cannabis-history",
    component: () => import("/tmp/build_9a90b56e/pages/learn/cannabis-history.vue")
  },
  {
    name: "learn-michigan-dispensary-purchase-limits",
    path: "/learn/michigan-dispensary-purchase-limits",
    component: () => import("/tmp/build_9a90b56e/pages/learn/michigan-dispensary-purchase-limits.vue")
  },
  {
    name: "learn-terpenes-over-potency",
    path: "/learn/terpenes-over-potency",
    component: () => import("/tmp/build_9a90b56e/pages/learn/terpenes-over-potency.vue")
  },
  {
    name: "learn-what-is-cannabis",
    path: "/learn/what-is-cannabis",
    component: () => import("/tmp/build_9a90b56e/pages/learn/what-is-cannabis.vue")
  },
  {
    name: "michigan-dispensary-near-indiana",
    path: "/michigan-dispensary-near-indiana",
    component: () => import("/tmp/build_9a90b56e/pages/michigan-dispensary-near-indiana.vue")
  },
  {
    name: "michigan-dispensary-near-ohio",
    path: "/michigan-dispensary-near-ohio",
    component: () => import("/tmp/build_9a90b56e/pages/michigan-dispensary-near-ohio.vue")
  },
  {
    name: "newsletter",
    path: "/newsletter",
    component: () => import("/tmp/build_9a90b56e/pages/newsletter.vue")
  },
  {
    name: "order-tracker",
    path: "/order-tracker",
    component: () => import("/tmp/build_9a90b56e/pages/order-tracker.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/tmp/build_9a90b56e/pages/privacy-policy.vue")
  },
  {
    name: "products-slug",
    path: "/products/:slug()",
    component: () => import("/tmp/build_9a90b56e/pages/products/[slug].vue")
  },
  {
    name: "quick-order",
    path: "/quick-order",
    component: () => import("/tmp/build_9a90b56e/pages/quick-order.vue")
  },
  {
    name: "return-policy",
    path: "/return-policy",
    component: () => import("/tmp/build_9a90b56e/pages/return-policy.vue")
  },
  {
    name: "reviews",
    path: "/reviews",
    component: () => import("/tmp/build_9a90b56e/pages/reviews.vue")
  },
  {
    name: "staging-login",
    path: "/staging-login",
    meta: staging_45login8xbpU6WjQOMeta || {},
    component: () => import("/tmp/build_9a90b56e/pages/staging-login.vue")
  },
  {
    name: "strain-library",
    path: "/strain-library",
    component: () => import("/tmp/build_9a90b56e/pages/strain-library.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/tmp/build_9a90b56e/pages/terms-of-use.vue")
  },
  {
    name: "thank-you",
    path: "/thank-you",
    component: () => import("/tmp/build_9a90b56e/pages/thank-you.vue")
  },
  {
    name: "weedys-wear",
    path: "/weedys-wear",
    component: () => import("/tmp/build_9a90b56e/pages/weedys-wear.vue")
  },
  {
    name: "wholesale",
    path: "/wholesale",
    component: () => import("/tmp/build_9a90b56e/pages/wholesale.vue")
  }
]